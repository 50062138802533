import {PostsActions, TagsActions, CategoriesActions} from "./blog.actionTypes";


const initialState = {
	isLoading: false,
	posts: [],
	tags: [],
	categories: [],
	errorMessage: "",
};

export const blogReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case PostsActions.POSTS_LOAD_START:
			return {
				...state,
				isLoading: true,
			};
		case PostsActions.POSTS_LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				posts: payload,
			};
		case TagsActions.TAGS_LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				tags: payload,
			};
		case CategoriesActions.CATEGORIES_LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				categories: payload,
			};
		case PostsActions.POSTS_LOAD_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: payload,
			};

		default:
			return state;
	}
};

