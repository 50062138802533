import { Tags, BlogMenu, BlogCategories } from '../../dummyData';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCategories, GetPosts, GetTags } from '../../redux/thunk/blog.thunks';


function BlogNav() {



    const dispatch = useDispatch();
const { isLoading, posts, tags, categories, errorMessage } = useSelector((state) => state.blog);

useEffect(() => {
    dispatch(GetPosts());
    dispatch(GetTags());
    dispatch(GetCategories());
}, []);

    return (
                    <div>
                        <nav className="container sm-none">
                            <ul className="nav-bar px-auto">
                                {categories.data &&
                                categories.data.map((category, index)  => (
                                    <span className="blog-tag" key={index}>
                                        <Link to={`/blog/${category.attributes.name}`}>
                                            <li className="nav-item active">
                                                <span
                                                    className={`nav-link ${
                                                        category.attributes.name == 'Development'
                                                            ? 'active-blog-menu text-red'
                                                            : ''
                                                    }`}>
                                                    {category.attributes.name}{' '}
                                                    <span className="sr-only">(current)</span>
                                                </span>
                                            </li>
                                        </Link>
                                    </span>
                                ))}
                            </ul>
                        </nav>

                        <nav
                            class="navbar navbar-expand-lg navbar-light md-none"
                            style={{ justifyContent: 'center' }}>
                            <button
                                class="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarTogglerDemo03"
                                aria-controls="navbarTogglerDemo03"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                                    {BlogMenu.map((menu, index) => (
                                        <li key={index} class="nav-item">
                                            <a class="nav-link disabled" href="#">
                                                {menu.menu}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </nav>
                    </div>
    )
}

export default BlogNav;
