import axios from 'axios';

const api = () => {
    const { REACT_APP_API_URL } = process.env;

    const axiosInstance = axios.create({
        baseURL: REACT_APP_API_URL,
        responseType: 'json',
        timeout: 200000,
        headers: {
            'Content-type': 'application/json'
        }
    });
    return axiosInstance;
};

export default api;
