import actions from "../actions/blog.actions";
import api from '../../api/apiClient';

export const GetPosts = () => (dispatch) => {
	dispatch(actions.usersLoadStart());
	api().get('posts')
	.then((response) =>dispatch(actions.usersLoadSuccess(response.data)))
	.catch((error) =>  dispatch(actions.usersLoadError(error.message)));
};
export const GetTags = () => (dispatch) => {
	dispatch(actions.tagsLoadStart());
	api().get('tags')
	.then((response) =>dispatch(actions.tagsLoadSuccess(response.data)))
	.catch((error) =>  dispatch(actions.tagsLoadError(error.message)));
};
export const GetCategories = () => (dispatch) => {
	dispatch(actions.tagsLoadStart());
	api().get('categories')
	.then((response) =>dispatch(actions.categoriesLoadSuccess(response.data)))
	.catch((error) =>  dispatch(actions.categoriesLoadError(error.message)));
};
