// import { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';
import BlogNav from '../../components/Blog/BlogNav';
import BlogTopNav from '../../components/Blog/BlogTopNav';
import BlogShowcase from '../../components/Blog/BlogShowcase';
import { Link } from 'react-router-dom';
import '../../css/blog.css';
import '../../css/blogNavbar.css';
import { Pagination } from 'antd';
import { Tag, Divider } from 'antd';
import { Tags, BlogMenu, BlogCategories } from '../../dummyData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCategories, GetPosts, GetTags } from '../../redux/thunk/blog.thunks';

function Blog() {
    const dispatch = useDispatch();
    const { isLoading, posts, tags, categories, errorMessage } = useSelector((state) => state.blog);
    useEffect(() => {
        dispatch(GetPosts());
        dispatch(GetTags());
        dispatch(GetCategories());
    }, []);
    return (
        <div className="blog">
            <BlogTopNav id="top" />
            <section className="blog-section section-one">
                <BlogShowcase />
            </section>
            <section className="main bg-teal">
                <section className="blog-section section-two">
                    <BlogNav categories={categories} />
                </section>
                <section className="container md-none">
                    <div className="mb-20 bg-white p-10">
                        <span className="mr-10 tag-label pl-10">Search blog</span>
                        <p>
                            <div className="">
                                <div className="blog-div-search mt-10">
                                    <button type="submit" className="blog-input-search">
                                        <svg
                                            className="input-search-button"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            id="Capa_1"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 56.966 56.966"
                                            xmlSpace="preserve"
                                            width="512px"
                                            height="512px">
                                            <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                                        </svg>
                                    </button>
                                    <input
                                        className=" blog-input-2 "
                                        type="search"
                                        name="search"
                                        placeholder="Type here"
                                    />
                                </div>
                            </div>
                        </p>
                    </div>
                    <div className="mb-20 bg-white p-10">
                        <span className="mr-10 tag-label pl-10">Tags</span>
                        <div className="row">
                            {categories.data &&
                                categories.data.map((category, index) => (
                                    <div key={index} className="col-6 mt-0 ">
                                        <div className=" px-10">
                                            <Link to={'#'}>
                                                {' '}
                                                <p className="tag-label text-black inline mr-10 text-black-200">
                                                    {category.attributes.name}
                                                </p>
                                            </Link>
                                            <span className="text-gray-100">
                                                {'('}
                                                {category.id}
                                                {')'}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </section>
                <section className="blog-showcase container">
                    <div className="row">
                        <div className="col-md-7 mt-2  p-10">
                            {posts.data &&
                                posts.data.slice(0, 1).map((post, index) => (
                                    <div className="bg-white p-20">
                                        <img
                                            src={`${
                                                post.attributes.image_url
                                                    ? `https://cms.huzz.africa${post.attributes.image_url}`
                                                    : '/assets/gim / blog / main - bg.svg'
                                            }`}
                                            className="max-width-fill"
                                        />
                                        <div className="py-10">
                                            {' '}
                                            <span className="mr-10 tag-label">DEVELOPMENT</span>
                                            <span>October 13,2021</span>
                                        </div>
                                        <h1 className="tag-label text-green text-36">
                                            {post.attributes.title}
                                        </h1>
                                        <p className="sm-elipsis-6">
                                            {post.attributes.description}
                                        </p>
                                        <div className="py-10 flex justify-between">
                                            {' '}
                                            <span className="text-red underline">
                                                {' '}
                                                <Link
                                                    to={`/blog/details`}
                                                    rel="noreferrer"
                                                    className="mr-10 text-red underline font-weight-500">
                                                    Read more
                                                </Link>
                                            </span>
                                            <span className="flex">
                                                <a
                                                    href="#"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="mr-10">
                                                    {' '}
                                                    <span>
                                                        <img src="./assets/img/blog/like.svg" />
                                                    </span>{' '}
                                                </a>
                                                <a
                                                    href="#"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="mr-10">
                                                    {' '}
                                                    <span>
                                                        <img src="./assets/img/blog/comment.svg" />
                                                    </span>{' '}
                                                </a>
                                                <a
                                                    href="#"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="mr-10">
                                                    {' '}
                                                    <span>
                                                        <img src="./assets/img/blog/share.svg" />
                                                    </span>{' '}
                                                </a>
                                                <a
                                                    href="#"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="mr-10">
                                                    {' '}
                                                    <span>
                                                        <img src="./assets/img/blog/bookmark.svg" />
                                                    </span>{' '}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="col-md-5 mt-2  p-10 sm-none">
                            <div className=" ">
                                <div className="mb-20 bg-white p-10 ">
                                    <span className="mr-10 tag-label pl-10">Search blog</span>
                                    <p>
                                        <div className="">
                                            <div className="blog-div-search mt-10">
                                                <button type="submit" className="blog-input-search">
                                                    <svg
                                                        className="input-search-button"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        version="1.1"
                                                        id="Capa_1"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 56.966 56.966"
                                                        xmlSpace="preserve"
                                                        width="512px"
                                                        height="512px">
                                                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                                                    </svg>
                                                </button>
                                                <input
                                                    className=" blog-input-2 "
                                                    type="search"
                                                    name="search"
                                                    placeholder="Type here"
                                                />
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div className="mb-20 bg-white p-10 ">
                                    <span className="mr-10 tag-label pl-10">Categories</span>
                                    <div className="row">
                                        {categories.data &&
                                            categories.data.map((category, index) => (
                                                <div key={index} className="col-6 mt-0 ">
                                                    <div className=" px-10">
                                                        <Link to={'#'}>
                                                            {' '}
                                                            <p className="tag-label text-black inline mr-10 text-black-200">
                                                                {category.attributes.name}
                                                            </p>
                                                        </Link>
                                                        <span className="text-gray-100">
                                                            {'('}
                                                            {category.id}
                                                            {')'}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="mb-20 bg-white p-10">
                                    <span className="mr-10 tag-label pl-10">Recent Posts</span>

                                    {posts.data &&
                                        posts.data.slice(0, 3).map((post, index) => (
                                            <div key={index} className="row mb-10">
                                                <div className="col-4 mt-2 ">
                                                    <div className=" pl-10">
                                                        <img
                                                            src={`${
                                                                post.attributes.image_url
                                                                    ? `https://cms.huzz.africa${post.attributes.image_url}`
                                                                    : '/assets/img/blog/blog-post-image.svg'
                                                            }`}
                                                            className="max-width-fill"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-8   pr-10">
                                                    <div className="">
                                                        <p className="line-height text-12 elipsis-3">
                                                            {post.attributes.description}
                                                        </p>
                                                        <Link to={`/blog/details`}>
                                                            {' '}
                                                            <span className="text-red underline text-12 right mr-10">
                                                                Read more
                                                            </span>{' '}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div className="mb-20 bg-white p-10">
                                    <span className="mr-10 tag-label pl-10">Tags</span>

                                    {tags.data &&
                                        tags.data.map((tag, index) => (
                                            <span className="blog-tag" key={index}>
                                                <Tag
                                                    color="rgba(7, 165, 142, 0.25)"
                                                    className="blog-tag">
                                                    {tag?.attributes.name}
                                                </Tag>
                                            </span>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="showcase container ">
                    <div className="row">
                        {posts.data &&
                            posts.data.slice(0, 4).map((post, index) => (
                                <div key={index} className="col-md-6  mt-2  p-10">
                                    <div className="bg-white">
                                        <div className="row">
                                            <div className="col-6 mt-2 ">
                                                <div className=" p-10">
                                                    <img
                                                        src={`${
                                                            post.attributes.image_url
                                                                ? `https://cms.huzz.africa${post.attributes.image_url}`
                                                                : '/assets/img/blog/main-bg-sub-main.svg'
                                                        }`}
                                                        className="max-width-fill"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6   p-10">
                                                <div className="mr-10">
                                                    <h4 className="weight-700 sm-elipsis-2">
                                                        {post.attributes.title}
                                                    </h4>
                                                    <p className="text-14 sm-elipsis-2">
                                                        {post.attributes.description}
                                                    </p>
                                                    <Link to={`/blog/details`}>
                                                        {' '}
                                                        <p className="text-red underline text-14">
                                                            Read more
                                                        </p>{' '}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </section>
            </section>
            <section className=" bg-teal">
                <div className="showcase container m-auto flex justify-center p-10 ">
                    <Pagination size="small" total={50} />{' '}
                </div>
                <br />
            </section>

            <ScrollTop />
            <Footer />
        </div>
    );
}
export default Blog;
