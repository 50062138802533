export const dummyAccordionData = [
    {
        id: '1',
        no: 'One',
        title: '1. How do I get started?',
        content: `Get started in 3 quick steps <br/> Step 1. Download and install the Huzz app from the Apple app store or Google Playstoreand launch it from your Android or iOS device <br/> Step 2. Quickly confirm your identity by verifying your phone number <br/>  Step 3. Enter basic information about yourself and your business then start using Huzz.`
    },
    {
        id: '2',
        no: 'Two',
        title: '2. Who can use the app?',
        content:
            '- Entrepreneurs or small business owners who want to fully manage their business. - Professionals or freelancers can use Huzz to generate invoices and receive payments from their clients. <br/> - Salary earners can use Huzz to log and track their income and expenses. '
    },
    {
        id: '3',
        no: 'Three',
        title: '3. Is the Huzz App free?',
        content:
            'Yes, it is free to use the Huzz app. In the future, we may introduce some premium features for a small recurring fee, however, anyone will still be able to access the basic functionalities for free without payment.'
    },
    {
        id: '4',
        no: 'Four',
        title: '4.  How does Huzz make money?',
        content:
            'We do not make any money at the moment. However, in the future, we will make money from processing fees when users receive payment on our platform, as well as through recurring subscriptions to our premium features.'
    },
    {
        id: '5',
        no: 'Five',
        title: '5. What platforms can I use Huzz on?',
        content:
            'Huzz is currently available on the iOS app store and Google Playstore. It can be used on apple and android mobile devices. We are also working on making Huzz available on the web. '
    },
    {
        id: '6',
        no: 'Six',
        title: '6. How secure is my information?',
        content:
            'Huzz is conscious of the security of your data. Your critical personal and business data are encrypted and securely stored. We also transmit your data via secure protocols.'
    },
    {
        id: '7',
        no: 'Seven',
        title: '7. What exactly can I do with the Huzz app?',
        content:
            'With the Huzz app, you can record transactions, manage customers, manage products, recover debts, issue invoices, issue receipts, and generate insights for your business. Very soon, you will be able to receive payments via the Huzz app.'
    },
    {
        id: '8',
        no: 'Eight',
        title: '8. How does the Offline feature work?',
        content:
            'After initial sign-up and login to the app which requires internet data, you will be able to perform basic functionalities like recording transactions and viewing recent transactions when you’re offline. At some point, you will be required to sync the offline data to the cloud in order to keep your app working optimally'
    },
    {
        id: '9',
        no: 'Nine',
        title: '9. How does the debt recovery feature work?',
        content:
            'When you record new transactions, you can specify if the money was fully paid or not. Clicking on the “Debts” button on your dashboard will show you all the transactions that are not fully paid. You can then set a one-time or repeated reminder for any of the transactions.'
    },
    {
        id: '10',
        no: 'Ten',
        title: '10. Can I create a Nigerian bank account on Huzz?',
        content:
            'At the moment no, but we are working on making that available. Very soon, with the help of financial partners, you will be able to create a dedicated bank account for your business.'
    },
    {
        id: '11',
        no: 'Eleven',
        title: '11. How can I get Support?',
        content:
            'Please send a message to <a href="mailto:info@huzz.africa"> <span><b>info@huzz.africa</b></span> </a>. You can  also connect with us on Instagram via the huzz.africa handle or call us via <a href="tel:+234 813 289 4616"><span><b>+234 813 289 4616</b></span></a>. We will respond as soon as possible.'
    },
    {
        id: '12',
        no: 'Twelve',
        title: '12. Do you have a Physical Address?',
        content:
            'Our current CAC registered physical address is 6, Oluwatoyin Close, Ikeja, Lagos. However, we do not currently accept appointments.'
    }
];
export const appLinks = {
    android: 'https://play.google.com/store/apps/details?id=com.app.huzz',
    ios: 'https://apps.apple.com/app/id1596574133'
};


export const Feedbacks = [
    {
        id: '1',
        title: 'Laraberyl Fabrics',
        stars: 5,
        imageURI: './images/',
        content:
            'Every business owner needs this app, especially a business that is just starting. It helps to track your finances without any stress.'
    },
    {
        id: '2',
        title: 'Temiasese Foods',
        stars: 5,
        imageURI: './images/',
        content: 'I use huzz app. It is amazing I must say.👏👏'
    },
    {
        id: '2',
        title: 'Temiasese Foods',
        stars: 5,
        imageURI: './images/',
        content: 'I use huzz app. It is amazing I must say.👏👏'
    },
    {
        id: '2',
        title: 'Temiasese Foods',
        stars: 5,
        imageURI: './images/',
        content: 'I use huzz app. It is amazing I must say.👏👏'
    },
    {
        id: '2',
        title: 'Temiasese Foods',
        stars: 5,
        imageURI: './images/',
        content: 'I use huzz app. It is amazing I must say.👏👏'
    }
];


export const Tags = [
  {
    id: "1",
    tag: "Lay",
  },
  {
    id: "2",
    tag: "Tem",

  },
  {
    id: "3",
    tag: "Temiase",

  },
  {
    id: "4",
    tag: "Temias",

  },
  {
    id: "5",
    tag: "Temiases",

  },
];
export const BlogMenu = [
  {
    id: "1",
    menu: "All",
    link: "./blog/details"
  },
  {
    id: "2",
    menu: "News",
    link: "./blog/details"

  },
  {
    id: "3",
    menu: "Updates",
    link: "./blog/details"

  },
  {
    id: "4",
    menu: "Development",
    link: "./blog/details"

  },
  {
    id: "5",
    menu: "Business",
    link: "./blog/details"

  },
  {
    id: "6",
    menu: "Technology",
    link: "./blog/details"

  },
];

export const BlogCategories = [
  {
    id: 1,
    category: "Development",
    number: 3,
    link: "./blog/details"
  },
  {
    id: 2,
    category: "Business",
    number: 98,
    link: "./blog/details"
  },
  {
    id: 3,
    category: "Career",
    number: 57,
    link: "./blog/details"
  },
  {
    id: 4,
    category: "Start-Up",
    number: 78,
    link: "./blog/details"
  },
  {
    id: 5,
    category: "Technology",
    number: 99,
    link: "./blog/details"
  },
  {
    id: 6,
    category: "Crypto",
    number: 8,
    link: "./blog/details"
  },
  {
    id: 7,
    category: "Business",
    number: 120,
    link: "./blog/details"
  },
  {
    id: 8,
    category: "NFT",
    number: 6,
    link: "./blog/details"
  },
 
];
