import React from 'react';
import { Link } from 'react-router-dom';
function footer() {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="footer-flex">
            <div className="row">
              <div className="col-md-3">
                <center>
                  <img
                    className="footer-logo"
                    src="/assets/img/Group-3440.png"
                  />
                </center>
              </div>
              <div className="col-md-3">
                {/* <p><b>About us</b></p> */}
                <ul>
                  {/* <li>About Our company</li> */}
                  {/* <li>Refer a Friend</li> */}
                  <li>Contact</li>
                </ul>
                <ul className="social-icons">
                  <a
                    href="https://twitter.com/huzzafrica"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <li>
                      <img src="/assets/img/icons/social/twitter.svg" />
                    </li>{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/huzz-africa"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <li>
                      <img src="/assets/img/icons/social/linked-in.svg" />
                    </li>{" "}
                  </a>
                  <a
                    href="https://www.instagram.com/huzz.africa/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <li>
                      <img src="/assets/img/icons/social/instagram.svg" />
                    </li>{" "}
                  </a>
                  <a
                    href="https://web.facebook.com/huzztechnologies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <li>
                      <img src="/assets/img/icons/social/facebook.svg" />
                    </li>{" "}
                  </a>
                  <a
                    href="https://www.tiktok.com/@huzzafrica/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <li>
                      <img
                        src="/assets/img/icons/tiktok.png"
                        className="w-10"
                      />
                    </li>{" "}
                  </a>
                </ul>
              </div>
              <div className="col-md-3">
                <ul>
                  <Link to="./faq">
                    {" "}
                    <li>FAQ</li>{" "}
                  </Link>
                  {/* <li>Cookies Policy</li> */}
                  {/* <li className="mt-4"><b>090-919-857-764 </b></li> */}
                  <a href="mailto:info@huzz.africa">
                    {" "}
                    <li>
                      <b>info@huzz.africa</b>
                    </li>{" "}
                  </a>
                </ul>
              </div>
              <div className="col-md-3">
                <Link to="./privacy-policy#top">
                  {" "}
                  <li>Privacy Policy</li>{" "}
                </Link>
                <ul>
                  {/* <li>Help and Support</li> */}
                  <Link to="./terms-of-use#top">
                    {" "}
                    <li>Terms and Condition</li>{" "}
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default footer;
