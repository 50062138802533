import {
    PostsActions,
	TagsActions,
	CategoriesActions
}
 from "../reducers/blog.actionTypes";

const usersLoadStart = () => ({
	type: PostsActions.POSTS_LOAD_START,
});

const usersLoadSuccess = (posts) => ({
	type: PostsActions.POSTS_LOAD_SUCCESS,
	payload: posts,
});

const usersLoadError = (errorMessage) => ({
	type: PostsActions.POSTS_LOAD_ERROR,
	payload: errorMessage,
});


const tagsLoadStart = () => ({
	type: TagsActions.TAGS_LOAD_START,
});

const tagsLoadSuccess = (tags) => ({
	type: TagsActions.TAGS_LOAD_SUCCESS,
	payload: tags,
});

const tagsLoadError = (errorMessage) => ({
	type: TagsActions.TAGS_LOAD_ERROR,
	payload: errorMessage,
});

const categoriesLoadStart = () => ({
	type: CategoriesActions.CATEGORIES_LOAD_START,
});

const categoriesLoadSuccess = (categories) => ({
	type: CategoriesActions.CATEGORIES_LOAD_SUCCESS,
	payload: categories,
});

const categoriesLoadError = (errorMessage) => ({
	type: CategoriesActions.CATEGORIES_LOAD_ERROR,
	payload: errorMessage,
});

export default {
	usersLoadStart,
	usersLoadSuccess,
	usersLoadError,
	tagsLoadStart,
	tagsLoadSuccess,
	tagsLoadError,
	categoriesLoadStart,
	categoriesLoadSuccess,
	categoriesLoadError,
};
