import React from "react";
import { Link } from "react-router-dom";
import { useState } from 'react';


function Navbar() {

  const [isOpen, setIsOpen] = useState(false);
  const ToggleNavIcon = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <div className="main-navbar">
        <nav className="navbar navbar-expand-lg container z-100">
          <Link to="/">
            {" "}
            <a className="navbar-brand">
              <img src="/assets/img/icons/huzz-logo.svg" />
            </a>{" "}
          </Link>
          <form className="form-inline my-2 my-lg-0 d-none">
            {/* <span>Login</span> */}
            {/* <a href="#"> <button class="btn btn-outline-success my-2 my-sm-0 ml-3" type="submit">Sign Up</button> </a> */}
          </form>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            
          >
          <span 
          // onClick={()=>ToggleNavIcon()}
          >
            {
    isOpen ? (
        <span>X</span>
    ) : (
        <>
            <img src="/assets/img/icons/logo.png" />{' '}
        </>
    )
}

          </span>
          
           
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto"></ul>
            <ul className="navbar-nav mr-auto nav-custom">
              <Link to="/#">
                {" "}
                <li className="nav-item active">
                  <span className="nav-link">
                    Home <span className="sr-only">(current)</span>
                  </span>
                </li>
              </Link>
              {/* <Link  to="/comming-soon"> <li class="nav-item">
        <span class="nav-link" >About</span>
      </li></Link> */}
              <Link to="/faq#">
                {" "}
                <li className="nav-item">
                  <span className="nav-link">FAQ</span>
                </li>
              </Link>
              {/* <Link to="/blog#">
                {" "}
                <li className="nav-item">
                  <span className="nav-link">Blog</span>
                </li>
              </Link> */}
              <a href="mailto:info@huzz.africa">
                <li className="nav-item">
                  <span className="nav-link">Contact</span>
                </li>
              </a>
            </ul>

          </div>
        </nav>
      </div>
    </div>
  );
}
export default Navbar;
