import React from 'react';
import { Tags, BlogMenu, BlogCategories } from '../../dummyData';
import { Link } from 'react-router-dom';

function BlogShowcase(...props) {
    return (
        <div className="container">
            <div className="">
                <div className="">
                    <h1 className="mb-2 text-white sm-text-32">Welcome to Our Blog</h1>
                    <p className="showcase-center-allign-text sm-text-14">
                        For recent updates, news and other related info
                    </p>
                </div>
            </div>
        </div>
    );
}

export default BlogShowcase;
