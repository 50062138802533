import { useEffect } from 'react';
import Navbar from '../components/navbar';
import Accordion from '../components/accordion';
import Footer from '../components/footer';
import { dummyAccordionData, sampleTableData } from '../dummyData';
import AOS from 'aos';
import 'aos/dist/aos.css';
function CommingSoon() {
    useEffect(() => {
        AOS.init();
    });
    console.log(dummyAccordionData);
    return (
        <div>
            <Navbar />
            <section className="showcase container faq-hero">
                <div className="row">
                    <section className=" container">
                        <center>
                            <div className="row mb-5">
                                <div className="col-md-12 mt-20">
                                    <h1 data-aos="fade-up">Comming Soon</h1>
                                    <div className="mt-5 d-none">
                                        <center>
                                            {' '}
                                            <img className="" src="./assets/img/mail-1.png" />{' '}
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </center>
                    </section>
                </div>
            </section>
            <section className="container">
                <div className="row">
                    <div class="m-4"></div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
export default CommingSoon;
