// import { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';
import BlogNav from '../../components/Blog/BlogNav';
import BlogShowcase from '../../components/Blog/BlogShowcase';
// import { Carousel, InvalidAvatar } from '../components/carousel';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../css/blog.css';
import { Pagination } from 'antd';
import { Tag, Divider } from 'antd';
import { Tags, BlogMenu, BlogCategories } from '../../dummyData';

function BlogDetails() {
    return (
        <div className="blog">
            <Navbar id="top" />
            <section className="blog-section section-one">
                <BlogShowcase />
            </section>
            <section className="main bg-teal">
                <section className="blog-section section-two">
                    <BlogNav />
                </section>
                <section className="blog-showcase container">
                    <div className="row">
                        <div className="col-md-12 mt-2 p-10">
                            <div className="bg-white p-20">
                                <img
                                    src="/assets/img/blog/blog-details-full-image.svg"
                                    className="max-width-fill"
                                />
                                <div className="py-10">
                                    {' '}
                                    <span className="mr-10 tag-label">DEVELOPMENT</span>
                                    <span>October 13,2021</span>
                                </div>
                                <h1 className="tag-label text-green text-36">
                                    Discove better ways to collaborate
                                </h1>
                                <p>
                                    Invite your business partners or employees to collaboratively
                                    manage your business. Easily set privileges to ensure your data
                                    is protected. Organize your products and services on your Huzz
                                    app.Instantly access and share Invite your business partners or
                                    employees to collaboratively manage your business. Easily set
                                    privileges to ensure your data is protected. Organize your
                                    products and services on your Huzz app.Instantly access and
                                    share them with existing or potential customers. It's easy and
                                    supports multiple images.
                                </p>
                                <p>
                                    Invite your business partners or employees to collaboratively
                                    manage your business. Invite your business partners or employees
                                    to collaboratively manage your business. Easily set privileges
                                    to ensure your data is protected. Organize your products and
                                    services on your Huzz app.Instantly access and share them with
                                    existing or potential customers. It's easy and supports multiple
                                    images.
                                </p>
                                <br />
                                <p>
                                    Invite your business partners or employees to collaboratively
                                    manage your business. Easily set privileges to ensure your data
                                    is protected. Organize your products and services on your Huzz
                                    app.Instantly access and share them with existing or potential
                                    customers. It's easy and supports multiple images. Invite your
                                    business partners or employees to collaboratively manage your
                                    business. Easily set privileges to ensure your data is
                                    protected. Organize your products and services on your Huzz
                                    app.Instantly access and share them with existing or potential
                                    customers. It's easy and supports multiple images. Invite your
                                    business partners or employees to collaboratively manage your
                                    business. Easily set privileges to ensure your data is
                                    protected. Organize your products and services on your Huzz
                                    app.Instantly access and share them with existing or potential
                                    customers. It's easy and supports multiple images.
                                </p>
                                <p>
                                    Invite your business partners or employees to collaboratively
                                    manage your business. Easily set privileges to ensure your data
                                    is protected. Organize your products and services on your Huzz
                                    app.Instantly access and share them with existing or potential
                                    customers. It's easy and supports multiple images.
                                </p>
                                <p>
                                    Invite your business partners or employees to collaboratively
                                    manage your business. Easily set privileges to ensure your data
                                    is protected. Organize your products and services on your Huzz
                                    app.Instantly access and share them with existing or potential
                                    customers. It's easy and supports multiple images.
                                </p>
                                <p>
                                    Invite your business partners or employees to collaboratively
                                    manage your business. Easily set privileges to ensure your data
                                    is protected. Invite your business partners or employees Invite
                                    your business partners or employees to collaboratively manage
                                    your business. Easily set privileges to ensure your data is
                                    protected. Organize Invite your business partners or employees
                                    to collaboratively manage your business. Easily set privileges
                                    to ensure your data is protected. Organize your products and
                                    services on your Huzz app.Instantly access and share them with
                                    existing or potential customers. It's easy and supports multiple
                                    images.
                                </p>
                                <br />
                                <p>
                                    Invite your business partners or employees to collaboratively
                                    manage your business. Easily set privileges to ensure your data
                                    is protected. Organize your products and services on your Huzz
                                    app.Instantly access and share them with existing or potential
                                    customers. It's easy and supports multiple images.
                                </p>
                                <p>
                                    Invite your Invite your business partners or employees to
                                    collaboratively manage your business. Easily set privileges to
                                    ensure your data is protected. Organize your Invite your
                                    business partners or employees to collaboratively manage your
                                    business. Easily set privileges to ensure your data is
                                    protected. Organize your products and services on your Huzz
                                    app.Instantly access and Invite your business partners or
                                    employees to collaboratively manage your business. Easily set
                                    privileges to ensure your data is protected. Organize Invite
                                    your business partners or employees to collaboratively manage
                                    your business. Easily set privileges to ensure your data is
                                    protected. Organize your products and services on your Huzz
                                    app.Instantly access and share them with existing or potential
                                    customers. It's easy and supports multiple images.
                                </p>
                                <div className="py-10 flex justify-between">
                                    {' '}
                                    <span className="text-red underline">
                                        {' '}
                                        <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10 text-red underline font-weight-500">
                                            .
                                        </a>
                                    </span>
                                    <span className="flex">
                                        <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/like.svg" />
                                            </span>{' '}
                                        </a>
                                        <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/comment.svg" />
                                            </span>{' '}
                                        </a>
                                        <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/share.svg" />
                                            </span>{' '}
                                        </a>
                                        <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/bookmark.svg" />
                                            </span>{' '}
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </section>

            <ScrollTop />
            <Footer />
        </div>
    );
}
export default BlogDetails;
