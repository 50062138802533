import React from 'react';
import './css/App.css';
import Index from "./screens/Index"
import Blog from "./screens/blog/Index"
import BlogDetail from "./screens/blog/BlogDetail"
import FAQ from "./screens/FAQ"
import PrivacyPolicy from "./screens/PrivacyPolicy"
import TermsOfUse from "./screens/TermsOfUse"
import CommingSoon from "./screens/CommingSoon"
import Test from "./Test"
import MobileFAQ from "./screens/mobile/FAQ"
import MobilePrivacyPolicy from "./screens/mobile/PrivacyPolicy"
import MobileTermsOfUse from "./screens/mobile/TermsOfUse"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
function App() {  
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Index} />
        <Route path='/faq' component={FAQ} />
        <Route path='/privacy-policy' component={PrivacyPolicy} />
        <Route path='/terms-of-use' component={TermsOfUse} />
        <Route path='/comming-soon' component={CommingSoon} />
        <Route path='/mobile/terms-of-use' component={MobileTermsOfUse} />
        <Route path='/mobile/privacy-policy' component={MobilePrivacyPolicy} />
        <Route path='/mobile/faq' component={MobileFAQ} />
        <Route path='/test' component={Test} />
        <Route exact path="/blog/details" component={BlogDetail} />;
        <Route path='/blog' component={Blog} />
      </Switch>
    </Router>
  );
}
export default App;
