import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
function ScrollTop(props) {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    setIsVisible(false);
  }, [1]);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);
  const listenToScroll = () => {
    let heightToHideFrom = 200;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  return (
    <div>
      {isVisible && (
        <a href="#top" id="hide">
          {" "}
          <button id="myBtn" className="animated pulse " title="Go to top">
            {" "}
            <img className="" src="/assets/img/icons/up.svg" />
          </button>
        </a>
      )}
    </div>
  );
}
export default ScrollTop;
