import { useEffect } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Accordion from '../../components/accordion';
import ScrollTop from '../../components/scrollTop';
import Pagination from '../../components/pagenation';
import { dummyAccordionData, sampleTableData } from '../../dummyData';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState } from 'react';
function MobileFAQ() {
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        AOS.init();
    });
    console.log(dummyAccordionData);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);
    useEffect(() => {
        setPosts(dummyAccordionData);
    }, []);
    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    console.log(currentPosts);
    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <div>
            {/* <Navbar /> */}
            <section className="showcase container faq-hero">
                <div className="row">
                    <section className=" container">
                        <center>
                            <div className="row mb-5">
                                <div className="col-md-12 mt-20">
                                    <h1 data-aos="fade-up">Hello! How can we help you?</h1>
                                    <div className="mt-5"></div>
                                    <div className="download-icons">
                                        <input
                                            onChange={(event) => {
                                                setSearchTerm(event.target.value);
                                            }}
                                            className="mt-5 mb-3"
                                            placeholder="Ask a question..."
                                            type="text"
                                            data-aos="fade-up"
                                        />
                                        {/* <button className="btn mt-0 ml-3" data-aos="fade-up">Search</button> */}
                                    </div>
                                </div>
                            </div>
                        </center>
                    </section>
                </div>
            </section>
            <section className="container">
                <div className="">
                    <div class="">
                        <div class="accordion" id="myAccordion">
                            {currentPosts
                                .filter((val) => {
                                    if (searchTerm == '') {
                                        return val;
                                    } else if (
                                        val.title
                                            .toLowerCase()
                                            .includes(searchTerm.toLowerCase()) ||
                                        val.content.toLowerCase().includes(searchTerm.toLowerCase())
                                    ) {
                                        return val;
                                    }
                                })
                                .map((x, i) => (
                                    <Accordion
                                        accordionTitle={x.title}
                                        accordionContent={x.content}
                                        accordionNumber={x.no}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="">
                    <div class="">
                        <div class="accordion" id="myAccordion">
                            <br />
                            {/* <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      /> */}
                        </div>
                    </div>
                </div>
            </section>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
            <p>
                <br />
            </p>
            {/* <Footer /> */}
            {/* <ScrollTop /> */}
        </div>
    );
}
export default MobileFAQ;
